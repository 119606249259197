var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-layout", {
    ref: "layout",
    attrs: { column: "", "justify-center": "", "fill-height": "" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }