<template>
  <v-layout column justify-center fill-height ref="layout"> </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { GameMixin } from "@/mixins"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
export default {
  name: "TwoTruths",
  components: {
    ResizableText
  },
  mixins: [GameMixin],
  props: {
    mode: String,
    mission: Object
  },
  mounted() {
    if (this.isScribe) {
      this.$nextTick(() => this.$refs.input_1?.focus())
    }

    // const script = document.createElement("script");
    // script.src="https://js.hsforms.net/forms/v2.js";
    // document.body.appendChild(script);
    // script.addEventListener("load", () => {
    //   if (window.hbspt) {
    //     window.hbspt.forms.create({
    //       portalId: "2803895",
    //       formId: "eab8a54e-41f5-4b45-8b0c-e4c6e75d7ab6",
    //       target: "#hubspotForm"
    //     })
    //   }
    // })
  },
  data() {
    return {
      // submited: false,
      status: "active",
      maxTextLength: 45,
      answer1: "",
      answer2: "",
      answer3: "",
      buttonStatus: "active"
    }
  },
  watch: {
    missionCompleted(newValue) {
      if (newValue) {
        this.buttonStatus = "inactive"
      } else {
        this.buttonStatus = "active"
      }
    }
  },
  computed: {
    ...mapGetters(["missionSuccess", "missionCompleted", "getCurrentMission"]),
    missionOptionsClass() {
      return {
        show: this.showOptions,
        hide: !this.showOptions
      }
    },
    showOptions() {
      return this.mode === "play" || this.mode === "huddle"
    },
    instructions() {
      return this.getCurrentMission?.instructions
    },
    isScribe() {
      return this.$store.getters.isScribe
    },
    isHost() {
      return this.user.role == "facilitator"
    }
  },
  methods: {
    // if correct
    async submit(answer, index) {
      if (!this.isScribe) return
      if (this.answer1 && this.answer2 && this.answer3) {
        this.buttonStatus = "inactive"
        const array = [this.answer1, this.answer2, this.answer3]
        this.answer = array
        await this.checkAnswer()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.text-field-submit {
  padding: 5px;
  font-weight: bold;
  color: $primary_accent_color;
  opacity: 1;
  pointer-events: none;
  cursor: not-allowed;
  transition: color 0.3s;
  user-select: none;
  z-index: 100;
}
.text-field-submit:not(.correct):hover,
.text-field-submit:not(.wrong):hover {
  /* filter: brightness(1.2); */
  opacity: 0.7;
}
.text-field-submit.active {
  color: $primary_accent_color;
  pointer-events: auto;
  cursor: pointer;
}

.text-field-submit.inactive {
  pointer-events: none;
  color: $color-grey-light3 !important;
}

.text-field-submit.correct {
  color: $color-correct;
  pointer-events: none;
  cursor: not-allowed;
}

.truth-title {
  text-align: left;
  font-size: 18px;
}

.mission-instructions-row {
  user-select: none;
  max-height: 40%;
  padding-left: 5px;
  padding-right: 5px;
}

.mission-instructions-row.full-size {
  max-height: 100%;
  margin-top: 21px;
  transition: all 0s ease-out 0.25s;
}

.mission-instructions-row.invisible {
  visibility: hidden;
}

.mission-instructions {
  color: $color-black;
  padding: 10px;
  line-height: 1.2em;
}

.appear-enter {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.appear-enter-active {
  opacity: 1;
}

.mission-options {
  position: relative;
  max-height: 0;
  padding: 20px;
}

.mission-options.show {
  max-height: 100%;
  transition: max-height 1s ease-out;
}

.mission-options.hide {
  transition: max-height 0.5s ease-out 0.25s;
}
</style>
