import { render, staticRenderFns } from "./SignUp.vue?vue&type=template&id=01691fa2&scoped=true&"
import script from "./SignUp.vue?vue&type=script&lang=js&"
export * from "./SignUp.vue?vue&type=script&lang=js&"
import style0 from "./SignUp.vue?vue&type=style&index=0&id=01691fa2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01691fa2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/Users/dkurbatov/Documents/TGG/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01691fa2')) {
      api.createRecord('01691fa2', component.options)
    } else {
      api.reload('01691fa2', component.options)
    }
    module.hot.accept("./SignUp.vue?vue&type=template&id=01691fa2&scoped=true&", function () {
      api.rerender('01691fa2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GroupTeams/Common/Games/SignUp.vue"
export default component.exports